<template>
    <div>
        <b-row v-show="prop_siteSettingArray.styleType != '7'">
            <!-- Add HTML -->
            <b-col md="12">
                <b-form-group label="Add HTML" label-for="htmlformodel">
                    <b-form-textarea name="htmlformodel" placeholder="<html>....</html>" v-model="prop_siteSettingArray.htmlformodel" />
                </b-form-group>
            </b-col>

            <!-- HTML placement -->
            <b-col md="12">
                <b-form-group label="HTML placement" label-for="modelhtmlplacement">
                    <Select2 :options="this.textModelHtmlPlacement" name="modelhtmlplacement" v-model="prop_siteSettingArray.modelhtmlplacement" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-show="prop_siteSettingArray.styleType == '7'">
            <!-- HTML Block 1 -->
            <b-col md="12">
                <b-form-group label="HTML Block 1" label-for="slideBarHtmlBlock1">
                    <b-form-textarea name="slideBarHtmlBlock1" placeholder="<html>....</html>" v-model="prop_siteSettingArray.slideBarHtmlBlock1" />
                </b-form-group>
            </b-col>

            <!-- HTML Block 2 -->
            <b-col md="12">
                <b-form-group label="HTML Block 2" label-for="slideBarHtmlBlock2">
                    <b-form-textarea name="slideBarHtmlBlock2" placeholder="<html>....</html>" v-model="prop_siteSettingArray.slideBarHtmlBlock2" />
                </b-form-group>
            </b-col>

            <!-- HTML Block 3 -->
            <b-col md="12">
                <b-form-group label="HTML Block 3" label-for="slideBarHtmlBlock3">
                    <b-form-textarea name="slideBarHtmlBlock3" placeholder="<html>....</html>" v-model="prop_siteSettingArray.slideBarHtmlBlock3" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BInputGroup, BFormTextarea } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BInputGroup, BFormTextarea
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        setup() {
            const { textModelHtmlPlacement, openURL } = captureOptions();
            return {
                textModelHtmlPlacement,
                openURL
            }
        },
        created() {
            /** SET DEFAULT IMAGE-PLACEMENT WHEN ADD NEW A CAPTURE */
            if (_.isEmpty(this.prop_siteSettingArray.modelhtmlplacement)) {
                this.prop_siteSettingArray.modelhtmlplacement = 'top';
            }
        }
    }
</script>